// applications constants

import { AllEnums } from "../@core/components/enums";
import {customFormatNotificationDateAndTime} from '../utility/Utils';
import moment from 'moment'
export const APIBaseURL = process.env.REACT_APP_API_URL;

export const APIBaseURLForRealTimeCommunication =
  process.env.REACT_APP_WEBSOCKET_BASEURL;

export const apiUrls = {
  postOperator: "/user/register",
  postDriver: "/user/register",
  forgotPass: "/users/forget-password/send-otp",
  resetPass: "/users/forget-password",
  getUser: "/users/me",
  getUserRoles: "/users/me/roles",
  updateUserCommunicationPreference: (preference) =>
    `/users/me/communication-preference/${preference}`,
  checkUserStatus: (email) => `/users/${email}/status`,
  resetPassword: "/users/reset-password",
  updatePassword: "/users/update-password",
  evaluateDriverStillParkedHash: (hash) => `/parking/events/${hash}/evaluate`,
  sendStillParkedRequest: (hash, isStillParked) =>
    `/parking/events/${hash}/still-parked/${isStillParked}`,
  updateDriver: "/users/me",
  updateOperator: "/users/me",
  getRoles: "/roles",
  addUser: "/users",
  updateUser: "/users",
  getUsers: "/parking-operators/me/users",
  deleteUser: "/users",
  addLocation: "/parking-operators/locations",
  updateLocation: "/parking-operators/locations",
  getLocations: "/parking-operators/locations",
  getTimezoneFromGoogleAddress:
    "https://maps.googleapis.com/maps/api/timezone/json",
  getOneLocation: "/parking-operators/locations/",
  deleteLocation: "/parking-operators/locations",
  addRole: "/roles",
  addLot: "/parking-operators/locations/lots",
  getLots: "/parking-operators/locations/lots",
  getLotsByOperatorID: (id) => `/parking-operators/${id}/locations/lots`,
  getLotsOfMultipleLocations: (ids) =>
    `/parking-operators/locations/${ids}/lots`,
  getOneLot: "/parking-operators/locations/lots/",
  updateLot: "/parking-operators/locations/lots",
  deleteLot: "/parking-operators/locations/lots",
  deleteRole: "/roles",
  updateRole: "/roles",
  deactivateRole: "/roles/status",
  deactivateLocation: "/parking-operators/locations/status",
  confirmByOtp: "/users/confirm",
  deactivateLot: "/parking-operators/locations/lots/status",
  getAdminOperators: "/parking-operators/admins",
  getAllOperators: "/parking-operators?pageSize=-1",
  getParkingOperators: "/parking-operators",
  getOperatorUsersByOperatorId: (id) => `/parking-operators/${id}/users`,
  getAdminDrivers: "/drivers",
  getOneUser: "/users/",
  onBoardUser: "/parking-operators/admins/on-board",
  getRatePlan: "/parking-operators/rate-plans/standard",
  getRatePlanData: "/parking-operators/location-entities/rate-plans/standard",
  updateRatePlan: "/parking-operators/rate-plans/standard",
  addRatePlan: "/parking-operators/rate-plans/standard",
  deleteRatePlan: "/parking-operators/rate-plans/standard/",
  oneRatePlan: "/parking-operators/rate-plans/standard/",
  updateRatePlan: "/parking-operators/rate-plans/standard",
  getOneRole: "/roles/",
  getRoleModules: "/roles/modules",
  getRoleActions: "/roles/actions",
  getParkingOperatorRoleModules: "/modules",
  deactivateRatePlan: "/parking-operators/rate-plans/standard/status",
  getSections: "/parking-operators/locations/lots/sections/",
  getAllSections: "/parking-operators/locations/lots/sections",
  getSectionsByOperatorID: (id) =>
    `/parking-operators/${id}/locations/lots/sections`,
  getSectionsOfMultipleLots: (ids) =>
    `/parking-operators/locations/lots/${ids}/sections`,
  connectLocation: "/parking-operators/location-entities/rate-plans/standard",
  connectLot: "/parking-operators/lots/rate-plans/standard",
  connectSection: "/parking-operators/sections/rate-plans/standard",
  getVariableRatePlanRules: "/parking-operators/rate-plans/variable/rules",
  addVariableRatePlanRule: "/parking-operators/rate-plans/variable/rules",
  deleteVaribleRatePlanRule: "/parking-operators/rate-plans/variable/rules/",
  oneVariableRatePlanRule: "/parking-operators/rate-plans/variable/rules/",
  deleteEntity: "",
  deactivateVariableRatePlanRule:
    "/parking-operators/rate-plans/variable/rules/status",
  updateVariableRatePlanRule: "/parking-operators/rate-plans/variable/rules",
  saveVariableRatePlansOrder:
    "/parking-operators/rate-plans/variable/rules/priority",
  getRatePlanOperators: "/parking-operators/rate-plans/configuration",
  updateRatePlanOperators: "/parking-operators/rate-plans/configuration",
  connectLocationWithVariable:
    "/parking-operators/location-entities/rate-plans/variable/connect",
  disconnectLocationWithVariable:
    "/parking-operators/location-entities/rate-plans/variable/disconnect",
  getAssociatedEntityDataStandard:
    "/parking-operators/location-entities/rate-plans/standard",
  getAssociatedEntityDataVariable:
    "/parking-operators/location-entities/rate-plans/variable",
  getOperatorsInPreferred: "/drivers/preferred-operators",
  getAllDrivers: "/drivers?pageSize=-1",
  onBoardDriver: "/drivers/me/on-board",
  createForm: "/parking-operators/questionnaires",
  getForm: "/parking-operators/me/questionnaires",
  updateForm: "/parking-operators/questionnaires",
  deleteForm: (id) => `/parking-operators/questionnaires/${id}/de-activate`,
  getParkingTickets: "/parking/tickets",
  getParkingTicketDetails: (id) => `/parking/tickets/${id}/events`,
  getParkingControllers: "/parking/events",
  getOperatorsQuestionnaires: (ids) => `/questionnaires/${ids.join(",")}`,
  getOperatorLocations: (id) =>
    `/parking-operators/${id}/locations?pageSize=-1`,
  addPreferredOperatorsFormResponses: "/drivers/me/preferred-operators",
  getPreferredOperators: "/drivers/me/preferred-operators",
  getOneOperatorQuestionnaires: (id) =>
    `/parking-operators/${id}/questionnaires`,
  getOneOperatorQuestionnairesResponse: (id) =>
    `/drivers/me/preferred-operators/${id}`,
  updatePreferredOperatorsFormResponse: "/drivers/me/preferred-operators",
  deletePreferredOperator: "/drivers/me/preferred-operators",
  getVehicles: "/drivers/me/vehicles",
  getVehicleColors: "/vehicles/colors",
  getVehicleMakes: "/vehicles/makes",
  loadModelsByMakeID: (id) => `/vehicles/makes/${id}/models`,
  addVehicle: "/drivers/me/vehicles",
  updateVehicle: "/drivers/me/vehicles",
  deleteVehicle: "/drivers/me/vehicles",
  addJsonInInvoice: "/parking/events",
  getFOBOrders: "/users/orders/fob-orders",
  assignFOB: "/users/orders/fob-orders/assign-fob",
  detachFOB: (id) => `/drivers/me/vehicles/${id}/detach-fob`,
  attachFOB: (id, fobId) => `/drivers/me/vehicles/${id}/attach-fobs/${fobId}`,
  getFOBsForDriver: (fobsType) => `/drivers/me/fobs/${fobsType}`,
  attachMultipleFOBs: "/drivers/me/vehicles/attach-fobs",
  getDriverDashboard: "/users/drivers/me/dashboard",
  getAdminDashboard: "/users/admins/me/dashboard",
  createOrder: "/users/orders",
  updateOrder: "/users/orders",
  getRatePlanRules: "",
  getDriverGroups: "/parking-operators/me/driver-groups",
  getDriverGroupsByOperatorIDs: (ids) =>
    `/parking-operators/driver-groups?operators=${ids}`,
  getDriverGroupByID: (id) => `/parking-operators/me/driver-groups/${id}`,
  addDriverGroup: "/parking-operators/me/driver-groups",
  updateDriverGroup: "/parking-operators/me/driver-groups",
  deleteDriverGroup: "/parking-operators/me/driver-groups",
  getDriverGroupCategories: "/driver-groups/criterias/categories",
  getDriverGroupCriteriaKeys: (id) =>
    `/driver-groups/criterias/categories/${id}/keys`,
  getDriverGroupCriteriaFilters: (categoryId, keyId) =>
    `/driver-groups/criterias/categories/${categoryId}/keys/${keyId}/filters`,
  changeDriverGroupStatus: (id, status) =>
    `/parking-operators/me/driver-groups/${id}/status/${status}`,
  getFOBs: "/parking-operators/me/things/fobs/parked",
  getRawDataByFOBID: (id) => `/parking/events/${id}`,
  getInvoicesByFOBID: (id) => `/parking/tickets/${id}`,
  getInvoiceDetails: (id) => `/parking/tickets/${id}/invoice`,
  getOperatorInvoiceDetails: (id) => `/parking/tickets/${id}/invoice`,
  userExists: (email) => `/users/exists/${email}`,
  addNonUserDriver: `/non-users/drivers`,
  getParkingOperatorInfoByZoneID: (id) =>
    `/parking-operators/location-entities/${id}/info`,
  getCostByStandardRatePlanAndDuration: (standardID, duration) =>
    `/parking-operators/rate-plans/standard/${standardID}/cost-for/${duration}`,
  getStripeClientSecret: `/users/drivers/one-time-pre-payment`,
  getHistories: `/users/me/histories/account`,
  setUsersPaymentMethodSettings: `/users/me/settings/payment-settings`,
  getUsersPaymentMethodSettings: `/users/me/settings/payment-settings`,
  getPaymentMethods: `/users/me/settings/payment-methods`,
  setPaymentMethodAsDefault: (id) =>
    `/users/me/settings/payment-methods/default/${id}`,
  generateStripeOnboardingLink: (returnURL, refreshURL) =>
    `/users/me/stripe/on-board/${encodeURIComponent(
      returnURL
    )}&${encodeURIComponent(refreshURL)}`,
  deletePaymentMethod: `/users/me/settings/payment-methods`,
  getStripeClientSecretForPaymentMethod: `/users/me/settings/payment-method-secret`,
  confirmPaymentByID: (intentID) => `/users/payments/confirm/${intentID}`,
  cancelPaymentByID: (intentID) => `/users/payments/cancel/${intentID}`,
  getParkingOperatorDashboard: (payload) => {
    let url = `/users/me/parking-operators/dashboard`;

    if (
      payload?.fromDate ||
      payload?.toDate ||
      payload?.locations ||
      payload?.lots ||
      payload?.sections ||
      payload?.driverGroups
    ) {
      url = `${url}?`;
    }

    if (payload?.fromDate) {
      if (url.at(-1) == "?") {
        url = `${url}fromDate=${payload?.fromDate}`;
      } else {
        url = `${url}&fromDate=${payload?.fromDate}`;
      }
    }

    if (payload?.toDate) {
      if (url.at(-1) == "?") {
        url = `${url}toDate=${payload?.toDate}`;
      } else {
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.locations) {
      if (url.at(-1) == "?") {
        url = `${url}locations=${payload?.locations}`;
      } else {
        url = `${url}&locations=${payload?.locations}`;
      }
    }

    if (payload?.lots) {
      if (url.at(-1) == "?") {
        url = `${url}lots=${payload?.lots}`;
      } else {
        url = `${url}&lots=${payload?.lots}`;
      }
    }

    if (payload?.sections) {
      if (url.at(-1) == "?") {
        url = `${url}sections=${payload?.sections}`;
      } else {
        url = `${url}&sections=${payload?.sections}`;
      }
    }

    if (payload?.driverGroups) {
      if (url.at(-1) == "?") {
        url = `${url}driverGroups=${payload?.driverGroups}`;
      } else {
        url = `${url}&driverGroups=${payload?.driverGroups}`;
      }
    }

    return url;
  },
  getParkingOperatorDashboardRevenue: (payload) => {
    let url = `/users/me/parking-operators/dashboard/revenue`;

    if (
      payload?.fromDate ||
      payload?.toDate ||
      payload?.locations ||
      payload?.lots ||
      payload?.sections ||
      payload?.driverGroups
    ) {
      url = `${url}?`;
    }

    if (payload?.fromDate) {
      if (url.at(-1) == "?") {
        url = `${url}fromDate=${payload?.fromDate}`;
      } else {
        url = `${url}&fromDate=${payload?.fromDate}`;
      }
    }

    if (payload?.toDate) {
      if (url.at(-1) == "?") {
        url = `${url}toDate=${payload?.toDate}`;
      } else {
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.locations) {
      if (url.at(-1) == "?") {
        url = `${url}locations=${payload?.locations}`;
      } else {
        url = `${url}&locations=${payload?.locations}`;
      }
    }

    if (payload?.lots) {
      if (url.at(-1) == "?") {
        url = `${url}lots=${payload?.lots}`;
      } else {
        url = `${url}&lots=${payload?.lots}`;
      }
    }

    if (payload?.sections) {
      if (url.at(-1) == "?") {
        url = `${url}sections=${payload?.sections}`;
      } else {
        url = `${url}&sections=${payload?.sections}`;
      }
    }

    if (payload?.driverGroups) {
      if (url.at(-1) == "?") {
        url = `${url}driverGroups=${payload?.driverGroups}`;
      } else {
        url = `${url}&driverGroups=${payload?.driverGroups}`;
      }
    }

    return url;
  },
  getParkingOperatorDashboardUtilization: (payload) => {
    let url = `/users/me/parking-operators/dashboard/utilization`;

    if (
      payload?.fromDate ||
      payload?.toDate ||
      payload?.locations ||
      payload?.lots ||
      payload?.sections ||
      payload?.driverGroups
    ) {
      url = `${url}?`;
    }

    if (payload?.fromDate) {
      if (url.at(-1) == "?") {
        url = `${url}fromDate=${payload?.fromDate}`;
      } else {
        url = `${url}&fromDate=${payload?.fromDate}`;
      }
    }

    if (payload?.toDate) {
      if (url.at(-1) == "?") {
        url = `${url}toDate=${payload?.toDate}`;
      } else {
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.locations) {
      if (url.at(-1) == "?") {
        url = `${url}locations=${payload?.locations}`;
      } else {
        url = `${url}&locations=${payload?.locations}`;
      }
    }

    if (payload?.lots) {
      if (url.at(-1) == "?") {
        url = `${url}lots=${payload?.lots}`;
      } else {
        url = `${url}&lots=${payload?.lots}`;
      }
    }

    if (payload?.sections) {
      if (url.at(-1) == "?") {
        url = `${url}sections=${payload?.sections}`;
      } else {
        url = `${url}&sections=${payload?.sections}`;
      }
    }

    if (payload?.driverGroups) {
      if (url.at(-1) == "?") {
        url = `${url}driverGroups=${payload?.driverGroups}`;
      } else {
        url = `${url}&driverGroups=${payload?.driverGroups}`;
      }
    }

    return url;
  },
  getParkingOperatorDashboardVisitTime: (payload) => {
    let url = `/users/me/parking-operators/dashboard/visit-time`;

    if (
      payload?.fromDate ||
      payload?.toDate ||
      payload?.locations ||
      payload?.lots ||
      payload?.sections ||
      payload?.driverGroups
    ) {
      url = `${url}?`;
    }

    if (payload?.fromDate) {
      if (url.at(-1) == "?") {
        url = `${url}fromDate=${payload?.fromDate}`;
      } else {
        url = `${url}&fromDate=${payload?.fromDate}`;
      }
    }

    if (payload?.toDate) {
      if (url.at(-1) == "?") {
        url = `${url}toDate=${payload?.toDate}`;
      } else {
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.locations) {
      if (url.at(-1) == "?") {
        url = `${url}locations=${payload?.locations}`;
      } else {
        url = `${url}&locations=${payload?.locations}`;
      }
    }

    if (payload?.lots) {
      if (url.at(-1) == "?") {
        url = `${url}lots=${payload?.lots}`;
      } else {
        url = `${url}&lots=${payload?.lots}`;
      }
    }

    if (payload?.sections) {
      if (url.at(-1) == "?") {
        url = `${url}sections=${payload?.sections}`;
      } else {
        url = `${url}&sections=${payload?.sections}`;
      }
    }

    if (payload?.driverGroups) {
      if (url.at(-1) == "?") {
        url = `${url}driverGroups=${payload?.driverGroups}`;
      } else {
        url = `${url}&driverGroups=${payload?.driverGroups}`;
      }
    }

    return url;
  },
  getParkingOperatorDashboardDrivers: (payload) => {
    let url = `/users/me/parking-operators/dashboard/drivers`;

    if (
      payload?.fromDate ||
      payload?.toDate ||
      payload?.locations ||
      payload?.lots ||
      payload?.sections ||
      payload?.driverGroups
    ) {
      url = `${url}?`;
    }

    if (payload?.fromDate) {
      if (url.at(-1) == "?") {
        url = `${url}fromDate=${payload?.fromDate}`;
      } else {
        url = `${url}&fromDate=${payload?.fromDate}`;
      }
    }

    if (payload?.toDate) {
      if (url.at(-1) == "?") {
        url = `${url}toDate=${payload?.toDate}`;
      } else {
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.locations) {
      if (url.at(-1) == "?") {
        url = `${url}locations=${payload?.locations}`;
      } else {
        url = `${url}&locations=${payload?.locations}`;
      }
    }

    if (payload?.lots) {
      if (url.at(-1) == "?") {
        url = `${url}lots=${payload?.lots}`;
      } else {
        url = `${url}&lots=${payload?.lots}`;
      }
    }

    if (payload?.sections) {
      if (url.at(-1) == "?") {
        url = `${url}sections=${payload?.sections}`;
      } else {
        url = `${url}&sections=${payload?.sections}`;
      }
    }

    if (payload?.driverGroups) {
      if (url.at(-1) == "?") {
        url = `${url}driverGroups=${payload?.driverGroups}`;
      } else {
        url = `${url}&driverGroups=${payload?.driverGroups}`;
      }
    }

    return url;
  },
  getParkingOperatorDashboardVariableRatePerformance: (payload) => {
    let url = `/users/me/parking-operators/dashboard/variable-rate-performance`;

    if (
      payload?.fromDate ||
      payload?.toDate ||
      payload?.locations ||
      payload?.lots ||
      payload?.sections ||
      payload?.driverGroups
    ) {
      url = `${url}?`;
    }

    if (payload?.fromDate) {
      if (url.at(-1) == "?") {
        url = `${url}fromDate=${payload?.fromDate}`;
      } else {
        url = `${url}&fromDate=${payload?.fromDate}`;
      }
    }

    if (payload?.toDate) {
      if (url.at(-1) == "?") {
        url = `${url}toDate=${payload?.toDate}`;
      } else {
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.locations) {
      if (url.at(-1) == "?") {
        url = `${url}locations=${payload?.locations}`;
      } else {
        url = `${url}&locations=${payload?.locations}`;
      }
    }

    if (payload?.lots) {
      if (url.at(-1) == "?") {
        url = `${url}lots=${payload?.lots}`;
      } else {
        url = `${url}&lots=${payload?.lots}`;
      }
    }

    if (payload?.sections) {
      if (url.at(-1) == "?") {
        url = `${url}sections=${payload?.sections}`;
      } else {
        url = `${url}&sections=${payload?.sections}`;
      }
    }

    if (payload?.driverGroups) {
      if (url.at(-1) == "?") {
        url = `${url}driverGroups=${payload?.driverGroups}`;
      } else {
        url = `${url}&driverGroups=${payload?.driverGroups}`;
      }
    }

    return url;
  },
  getParkingOperatorDriverReports: (payload) => {
    // var fromDate = new Date(payload.fromDate).toUTCString();
    // var toDate = new Date(payload.toDate).toUTCString();
    
    console.log('571')

    
    let url = `/users/me/parking-operators/reports/driver-based?isComparisonMode=${payload?.isComparisonMode}`;

    if (payload?.fromDate) {
      url = `${url}&fromDate=${payload?.fromDate}`;
    }
    if (payload?.toDate) {
      console.log("This is our payload", payload);
      
      if(AllEnums.ReportFiltersDateRange.Today == payload.dateRange || AllEnums.ReportFiltersDateRange.Yesterday == payload.dateRange){ 
        
        let endDate; // Declare outside the blocks

        if (payload?.dateRange == 2) {
            endDate = moment().utc().add(1, 'days').format("YYYY-MM-DD");
        } else if (payload?.dateRange == 3) {
            endDate = moment().utc().format("YYYY-MM-DD");
        }
        url = `${url}&toDate=${endDate}`;  
      }else{
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }

    if (payload?.driverGroups) {
      url = `${url}&driverGroup=${payload?.driverGroups}`;
    }

    if (payload?.comparisonDriverGroups) {
      url = `${url}&comparisonDriverGroup=${payload?.comparisonDriverGroups}`;
    }

    return url;
  },
  getParkingOperatorDriverReportsByOperatorID: (id, payload) => {
    let url = `/users/parking-operators/${id}/reports/driver-based?isComparisonMode=${payload?.isComparisonMode}`;

    if (payload?.fromDate) {
      url = `${url}&fromDate=${payload?.fromDate}`;
    }

    if (payload?.toDate) {
      url = `${url}&toDate=${payload?.toDate}`;
    }

    if (payload?.driverGroups) {
      url = `${url}&driverGroup=${payload?.driverGroups}`;
    }

    if (payload?.comparisonDriverGroups) {
      url = `${url}&comparisonDriverGroup=${payload?.comparisonDriverGroups}`;
    }

    return url;
  },
  getParkingOperatorDriverReportsByOperatorIDs: (payload) => {
    
    // Start constructing the URL
    let url = `/users/parking-operators/reports/driver-based?isComparisonMode=${payload?.isComparisonMode}`;
  
    // Append other parameters if they exist
    if (payload?.fromDate) {
      url = `${url}&fromDate=${payload?.fromDate}`;
    }
  
    if (payload?.toDate) {
      console.log("This is our payload", payload);
      
      if(AllEnums.ReportFiltersDateRange.Today == payload.dateRange || AllEnums.ReportFiltersDateRange.Yesterday == payload.dateRange){ 
        
        let endDate; // Declare outside the blocks

        if (payload?.dateRange == 2) {
            endDate = moment().utc().add(1, 'days').format("YYYY-MM-DD");
        } else if (payload?.dateRange == 3) {
            endDate = moment().utc().format("YYYY-MM-DD");
        }
        url = `${url}&toDate=${endDate}`;  
      }else{
        url = `${url}&toDate=${payload?.toDate}`;
      }
    }
  
    if (payload?.driverGroups) {
      url = `${url}&driverGroup=${encodeURIComponent(payload?.driverGroups)}`;
    }
  
    if (payload?.comparisonDriverGroups) {
      url = `${url}&comparisonDriverGroup=${encodeURIComponent(payload?.comparisonDriverGroups)}`;
    }
  
    if (payload?.operators) {
      url = `${url}&operators=${encodeURIComponent(payload?.operators)}`;
    }
  
    return url;
  },
    
  getParkingOperatorTimeReports: (payload) => {
    var fromDate = new Date(payload.fromDate).toUTCString();
    var toDate = new Date(payload.toDate).toUTCString();
  
    // Assign the formatted dates back to payload
    payload.fromDate = fromDate;
    payload.toDate = toDate;
    let url = `/users/me/parking-operators/reports/time-based?isComparisonMode=${payload?.isComparisonMode}&comparisonOn=${payload?.comparisonOn}`;

    if (payload?.fromDate) {
      url = `${url}&fromDate=${encodeURIComponent(fromDate)}`;
    }

    if (payload?.toDate) {
      url = `${url}&toDate=${encodeURIComponent(toDate)}`;
    }

    if (payload?.locations) {
      url = `${url}&locations=${payload?.locations}`;
    }

    if (payload?.lots) {
      url = `${url}&lots=${payload?.lots}`;
    }

    if (payload?.sections) {
      url = `${url}&sections=${payload?.sections}`;
    }

    if (payload?.driverGroups) {
      url = `${url}&driverGroups=${payload?.driverGroups}`;
    }

    if (payload?.comparisonFrom) {
      url = `${url}&comparisonFrom=${payload?.comparisonFrom}`;
    }

    if (payload?.comparisonTo) {
      url = `${url}&comparisonTo=${payload?.comparisonTo}`;
    }

    return url;
  },
  getParkingOperatorTimeReportsByOperatorID: (payload) => {
    var fromDate = new Date(payload.fromDate).toUTCString();
    var toDate = new Date(payload.toDate).toUTCString();
    
  
    // Assign the formatted dates back to payload
    payload.fromDate = fromDate;
    payload.toDate = toDate;
    let url = `/users/parking-operators/reports/time-based?isComparisonMode=${payload?.isComparisonMode}&comparisonOn=${payload?.comparisonOn}`;

    if (payload?.fromDate) {
      url = `${url}&fromDate=${fromDate}`;
    }

    if (payload?.toDate) {
      url = `${url}&toDate=${toDate}`;
    }

    if (payload?.locations) {
      url = `${url}&locations=${payload?.locations}`;
    }

    if (payload?.lots) {
      url = `${url}&lots=${payload?.lots}`;
    }

    if (payload?.sections) {
      url = `${url}&sections=${payload?.sections}`;
    }

    if (payload?.driverGroups) {
      url = `${url}&driverGroups=${payload?.driverGroups}`;
    }

    if (payload?.operators) {
      url = `${url}&operators=${payload?.operators}`;
    }

    if (payload?.comparisonFrom) {
      url = `${url}&comparisonFrom=${payload?.comparisonFrom}`;
    }

    if (payload?.comparisonTo) {
      url = `${url}&comparisonTo=${payload?.comparisonTo}`;
    }

    return url;
  },
  getRealTimeDashboardForOperator: `/parking-operators/dashboard`,
  exportDriverBasedReports: `/users/me/parking-operators/reports/driver-based/export`,
  exportTimeBasedReports: `/users/me/parking-operators/reports/time-based/export`,
  changeBlacklistStatusOfOperatorFOB: (id, status) =>
    `/[something]/${id}/status/${status}`,
  getCoupons: `/coupons`,
  getCouponByID: (id) => `/coupons/${id}`,
  createCoupon: `/coupons`,
  updateCoupon: `/coupons`,
  changeCouponStatus: (id, status) => `/coupons/${id}/status/${status}`,
  deleteCoupon: "/coupons",
  applyCoupon: (payload) => {
    let url = `/coupons/calculate-discount?coupon=${payload?.coupon}&totalAmount=${payload?.totalAmount}&userName=${payload?.userName}`;

    if (payload?.zoneID) {
      url = `${url}&zoneID=${payload?.zoneID}`;
    }

    return url;
  },
  getParkingTicketsByFilters: `/parking-operators/me/tickets`,
  getNotificationHistories: (notificationType, pageNumber) =>
    `/users/me/histories/notifications?notificationType=${notificationType}&pageNumber=${pageNumber}&sortDirection=DESC`,
  getNotificationSettings: `/users/me/settings/notification-settings/categories/notifications`,
  changeNotificationStatusOverall: (toggleFor, value) =>
    `/users/me/settings/notification-settings/${toggleFor}/categories/notifications/${value}`,
  changeNotificationStatusByCategoryID: (categoryID, toggleFor, value) =>
    `/users/me/settings/notification-settings/${toggleFor}/categories/${categoryID}/notifications/${value}`,
  changeNotificationStatusByNotificationID: (
    notificationID,
    toggleFor,
    value
  ) =>
    `/users/me/settings/notification-settings/${toggleFor}/categories/notifications/${notificationID}/${value}`,
  readSpecificNotification: (id) =>
    `/users/me/histories/notifications/${id}/read`,
  readAllNotifications: `/users/me/histories/notifications/read`,
  getRealTimeNotification: `/users/notifications`,
  impersonate: "/users/impersonate",
  resetImpersonation: "/users/reset-impersonation",
  //BEACONS
  getBeacons: "/things/beacons",
  addBeacon: "/things/beacons",
  updateBeacon: "/things/beacons",
  deleteBeacon: "/things/beacons",
  changeBeaconStatus: (id, status) => `/things/beacons/${id}/status/${status}`,
  getBeaconsOfOperator: `/parking-operators/me/things/beacons?pageSize=-1`,
  getOperatorFOBs: "/parking-operators/me/things/fobs/parked",
  //PARKING HISTORY
  getParkingHistory: "/drivers/me/parking-tickets",

  //OPERATOR BEACONS
  getOperatorBeacons: "/parking-operators/me/things/beacons",
  updateOperatorBeacon: "/parking-operators/me/things/beacons",
  changeOperatorBeaconStatus: (id, status) =>
    `/parking-operators/me/things/beacons/${id}/status/${status}`,
  getRealTimeBeaconsForOperator: `/parking-operators/things/beacons`,

  // THINGS FOBS
  getThingsFobs: "/things/fobs",
  addThingsFob: "/things/fobs",
  updateThingsFob: "/things/fobs",
  changeThingsFobStatus: (id, status) => `/things/fobs/${id}/status/${status}`,
  deleteThingsFob: "/things/fobs",

  // PERMITS
  getPermits: "/parking-operators/me/permits",
  addPermit: "/parking-operators/me/permits",
  updatePermit: "/parking-operators/me/permits",
  changePermitStatus: (id, status) =>
    `/parking-operators/me/permits/${id}/status/${status}`,
  deletePermit: "/parking-operators/me/permits",
  takePermission: (payload) =>
    `/parking-operators/permits/${payload?.title}&${payload?.value}`,

  // UTILIZATION LISTING
  getUtilizationListing: (id) => `/parking-operators/${id}/utilization`,
};

export const line_chart_Options = {
  chart: {
    type: "area",
    stacked: false,
    height: 350,
    zoom: {
      type: "x",
      enabled: true,
      //autoScaleYaxis: true,
    },
    toolbar: {
      autoSelected: "zoom",
      tools: {
        download: false,
      },
    },
    events: {
      beforeZoom: (e, { xaxis }) => {
        const seriesX = e?.w?.globals?.seriesX;
        let startDateInTicks = 0;
        let endDateInTicks = 0;

        if (seriesX?.length == 1) {
          startDateInTicks = seriesX[0][0];

          endDateInTicks = seriesX[0][seriesX[0].length - 1];
        } else if (seriesX?.length == 2) {
          const firstSeriesStartDateInTicks = seriesX[0][0];
          const firstSeriesEndDateInTicks = seriesX[0][seriesX[0].length - 1];

          const secondSeriesStartDateInTicks = seriesX[1][0];
          const secondSeriesEndDateInTicks = seriesX[1][seriesX[1].length - 1];

          if (firstSeriesStartDateInTicks < secondSeriesStartDateInTicks) {
            startDateInTicks = firstSeriesStartDateInTicks;
          } else {
            startDateInTicks = secondSeriesStartDateInTicks;
          }

          if (firstSeriesEndDateInTicks > secondSeriesEndDateInTicks) {
            endDateInTicks = firstSeriesEndDateInTicks;
          } else {
            endDateInTicks = secondSeriesEndDateInTicks;
          }
        }

        let maindifference = endDateInTicks - startDateInTicks;
        let zoomdifference = xaxis.max - xaxis.min;

        if (zoomdifference >= maindifference) {
          return {
            // dont zoom out any further
            xaxis: {
              min: startDateInTicks,
              max: endDateInTicks,
            },
          };
        } else {
          return {
            // keep on zooming
            xaxis: {
              min: xaxis.min,
              max: xaxis.max,
            },
          };
        }
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "datetime", //"category"
    tickPlacement: "on",
    labels: {
      hideOverlappingLabels: true,
      trim: true,
      datetimeUTC: false,
    },
    title: {
      text: "Date",
    },
  },
  noData: {
    text: "No Data",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "16px",
      fontFamily: undefined,
    },
  },
  markers: {
    strokeWidth: 7,
    strokeOpacity: 1,
    strokeColors: ["#fff"],
    colors: ["#469bd5", "#00FFFF"],
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: 3,
  },
  colors: ["#469bd5", "#00FFFF"],
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  tooltip: {
    custom(data) {
      return `<div class='px-1 py-50'>
              <span>${data.series[data.seriesIndex][data.dataPointIndex]}</span>
            </div>`;
    },
  },
  yaxis: {
    opposite: "ltr" === "rtl",
  },
};

export const beaconTypeOptions = [
  {
    id: AllEnums.BeaconType.None,
    name: "None",
  },
  {
    id: AllEnums.BeaconType.Entrance,
    name: "Entrance Beacon",
  },
  {
    id: AllEnums.BeaconType.Exit,
    name: "Exit Beacon",
  },
  {
    id: AllEnums.BeaconType.Both,
    name: "Both Entrance and Exit Beacon",
  },
];

export const reactTourStepsForDriver = [
  {
    selector: "#fobs",
    content: (
      <div>
        <h5 className="text-primary">Park.Easy Profile Tour</h5>
        <div>
          <p>Use this tab to:</p>
          <ul>
            <li>Order FOBs</li>
            <li>Active FOBs after receipt</li>
            <li>Assign or Reassign FOBs to your registered vehicles</li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    selector: "#vehicles",
    content: (
      <div>
        <h5 className="text-primary">Park.Easy Profile Tour</h5>
        <div>
          <p>Use this tab to:</p>
          <ul>
            <li>Add/Remove Vehicles</li>
            <li>Add/Remove FOBs</li>
            <li>View Last Parking Transactions</li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    selector: "#preferredoperators",
    content: (
      <div>
        <h5 className="text-primary">Park.Easy Profile Tour</h5>
        <div>
          <p>Use this tab to:</p>
          <ul>
            <li>Manage/Add/Remove Your Preferred Operators</li>
            <li>Manage/Add/Remove Your Long Term Permits</li>
          </ul>
        </div>
      </div>
    ),
  },
];

export const permitStatusOptions = [
  {
    id: AllEnums.PermitStatus.None,
    name: "None",
  },
  {
    id: AllEnums.PermitStatus.Valid,
    name: "Valid",
  },
  {
    id: AllEnums.PermitStatus.InValid,
    name: "Invalid",
  },
  {
    id: AllEnums.PermitStatus.Expired,
    name: "Expired",
  },
  {
    id: AllEnums.PermitStatus.Consumed,
    name: "Consumed",
  },
];
