import { useSelector } from "react-redux";
import moment from "moment/moment";
import { savePDF } from "@progress/kendo-react-pdf";
import ReactDOM from "react-dom";
import * as htmlToImage from "html-to-image";
import Swal from "sweetalert2";
import { AllEnums } from "../@core/components/enums";
import { store } from "@store/store";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * Below function returns true if profile is complete -> All fields in profile are saved (profile image can be ignored)
 */

export const isProfileComplete = () => {
  const user = useSelector((store) => store.auth.userData);
  if (
    user?.firstName &&
    user?.lastName &&
    user?.userName &&
    user?.dob &&
    user?.phoneNumber &&
    user?.gender &&
    user?.address &&
    user?.communicationPreference
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = userRole => {
//   if (userRole === 'admin') return '/'
//   if (userRole === 'client') return '/access-control'
//   return '/login'
// }

export const getHomeRouteForLoggedInUser = (userRole) => {
  if (localStorage.getItem("userData")) {
    return "/";
  } else {
    return "/login";
  }
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#cb2a7a", // for selected option bg-color
    neutral10: "#cb2a7a", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

// ** Check if the value is Object

export function isObject(obj) {
  return obj != null && obj.constructor.name === "Object";
}

// ** Validate JSON input

export function validateJson(json) {
  // Try to parse the JSON.
  try {
    JSON.parse(json);
    // If the parsing is successful, the validation passes.
    return true;
  } catch {
    // If the parsing fails, the error message will be displayed.
    return false;
  }
}

// Prevent Input field to accept minus value

export const preventMinus = (e) => {
  if (e.code === "Minus" || e.code === "KeyE") {
    e.preventDefault();
  }
};

// Prevent Input field to accept pasted minus value

export const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));

  if (pastedData < 0) {
    e.preventDefault();
  }
};

// Convert date time to ticks

export const dateTimeToTicks = (dateTime) => {
  const yourDate = new Date(dateTime); // for example

  // the number of .net ticks at the unix epoch
  const epochTicks = 621355968000000000;

  // there are 10000 .net ticks per millisecond
  const ticksPerMillisecond = 10000;

  // calculate the total number of .net ticks for your date
  const ticks = epochTicks + yourDate.getTime() * ticksPerMillisecond;

  return ticks;
};

// Get credit card type

export function GetCardType(number) {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "amex";

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "discover";

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "diners";

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]");
  if (number.match(re) != null) return "diners";

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "jcb";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "visa-electron";

  return "";
}

// Calculate Percent Difference of Two Numbers

export const calculatePercentDifference = (num1, num2) => {
  if (num1 && num2) {
    return ((Math.abs(num1 - num2) / ((num1 + num2) / 2)) * 100)?.toFixed(2);
  } else if (num1 && !num2) {
    return ((Math.abs(num1 - 0) / ((num1 + 0) / 2)) * 100)?.toFixed(2);
  } else if (!num1 && num2) {
    return ((Math.abs(0 - num2) / ((0 + num2) / 2)) * 100)?.toFixed(2);
  } else {
    return 0;
  }
};

// Calculate Percent Difference of Two Numbers After Rounding NUmber

export const calculatePercentDifferenceAfterRounding = (num1, num2) => {
  const number1 = num1 ? Math.round(num1) : num1;
  const number2 = num2 ? Math.round(num2) : num2;

  if (number1 && number2) {
    return (
      (Math.abs(number1 - number2) / ((number1 + number2) / 2)) *
      100
    )?.toFixed(2);
  } else if (number1 && !number2) {
    return ((Math.abs(number1 - 0) / ((number1 + 0) / 2)) * 100)?.toFixed(2);
  } else if (!number1 && number2) {
    return ((Math.abs(0 - number2) / ((0 + number2) / 2)) * 100)?.toFixed(2);
  } else {
    return 0;
  }
};

// Download file

export const downloadFile = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = url.split("/").pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Convert Base64 strong to Array Buffer

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (const i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

// Save Byte Array

export const saveByteArray = (reportName, byte, type) => {
  const blob = new Blob([new Uint8Array(byte)], { type: type });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

// Download ReactJSX as PDF

export const createPdf = (html) => {
  savePDF(ReactDOM.findDOMNode(html), {
    paperSize: "Letter",
    fileName: "Dashboard.pdf",
    margin: 3,
  });
};

// Format date

export const customFormatDate = (date) => {
  const formatType = "USA";

  if (formatType == "USA") {
    return date ? moment(date).format("MM-DD-YYYY") : "mm-dd-yyyy";
  } else if (formatType == "UK") {
    return date ? moment(date).format("DD-MM-YYYY") : "dd-mm-yyyy";
  } else {
    return date ? moment(date).format("YYYY-MM-DD") : "yyyy-mm-dd";
  }
};

// Format time

export const customFormatTime = (time) => {
  const formatType = "USA";

  if (formatType == "USA") {
    return time ? moment(time, "HH:mm").format("hh:mm a") : "hh:mm a";
  } else if (formatType == "UK") {
    return time ? moment(time, "HH:mm").format("hh:mm a") : "hh:mm a";
  } else {
    return time ? moment(time, "HH:mm").format("hh:mm a") : "hh:mm a";
  }
};

// Format date time

export const customFormatDateTime = (dateTime) => {
  const formatType = "USA";

  if (formatType == "USA") {
    return dateTime
      ? moment(dateTime).format("MM-DD-YYYY hh:mm a")
      : "mm-dd-yyyy hh:mm a";
  } else if (formatType == "UK") {
    return dateTime
      ? moment(dateTime).format("DD-MM-YYYY hh:mm a")
      : "dd-mm-yyyy hh:mm a";
  } else {
    return dateTime
      ? moment(dateTime).format("YYYY-MM-DD hh:mm a")
      : "yyyy-mm-dd hh:mm a";
  }
};

// Format today date

export const todayDate = () => {
  const formatType = "USA";

  if (formatType == "USA") {
    return moment().format("MM-DD-YYYY");
  } else if (formatType == "UK") {
    return moment().format("DD-MM-YYYY");
  } else {
    return moment().format("YYYY-MM-DD");
  }
};

// Format today date time

export const todayDateTime = () => {
  const formatType = "USA";

  if (formatType == "USA") {
    return moment().format("MM-DD-YYYY hh:mm a");
  } else if (formatType == "UK") {
    return moment().format("DD-MM-YYYY hh:mm a");
  } else {
    return moment().format("YYYY-MM-DD hh:mm a");
  }
};

// Format date in text form

export const customFormatDateInTextForm = (date) => {
  return date ? moment(date).format("MMM D, YY") : "";
};

// Format date and time in text form

export const customFormatDateTimeInTextForm = (date) => {
  return date ? moment(date).format("MMM D, YY HH:mm") : "";
};

// Format notification date time in text form

export const customFormatNotificationDateAndTime = (date) => {
  return date ? moment.utc(date).local().format("MMM D, YY h:mm:ss a") : "";
};

// save file

const saveAs = (blob, fileName) => {
  var elem = window.document.createElement("a");
  elem.href = blob;
  elem.download = fileName;
  elem.style = "display:none;";
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === "function") {
    elem.click();
  } else {
    elem.target = "_blank";
    elem.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

// export react element as image

export const exportReactElementAsImage = async (id, fileName) => {
  htmlToImage.toPng(document.getElementById(id)).then(function (dataUrl) {
    saveAs(dataUrl, `${fileName}.png`);
  });
};

// unsaved Form Modal Check function below

export const unsavedFormModalCheck = (
  isLoading,
  closeModal,
  isEdit,
  values,
  initialValues,
  initialValuesOnEdit,
  skin
) => {
  if (isLoading) {
    closeModal();
  } else {
    if (isEdit) {
      if (JSON.stringify(values) == JSON.stringify(initialValuesOnEdit)) {
        closeModal();
      } else {
        Swal.fire({
          allowOutsideClick: false,
          showCancelButton: true,
          showConfirmButton: true,

          title: `<h6>You have unsaved changes. Do you want to keep these changes?</h6>`,
          icon: "warning",
          confirmButtonText: "Keep",
          cancelButtonText: "Discard",
          confirmButtonColor: "#469bd5",
        }).then((result) => {
          if (result?.isDismissed) {
            closeModal();
          }
        });
      }
    } else {
      if (JSON.stringify(values) == JSON.stringify(initialValues)) {
        closeModal();
      } else {
        Swal.fire({
          allowOutsideClick: false,
          showCancelButton: true,
          showConfirmButton: true,

          title: `<h6>You have unsaved changes. Do you want to keep these changes?</h6>`,
          icon: "warning",
          confirmButtonText: "Keep",
          cancelButtonText: "Discard",
          confirmButtonColor: "#469bd5",
        }).then((result) => {
          if (result?.isDismissed) {
            closeModal();
          }
        });
      }
    }
  }
};

// create an object from array but pass zero values

function createObjectWithZeroValues(array, index) {
  const selectedObject = array[index];
  const keys = Object.keys(selectedObject);

  const newObject = {};
  for (const key of keys) {
    newObject[key] = 0;
  }

  return newObject;
}

// add missing dates to array

export function addMissingDatesToArray(array, dateTimeKey) {
  const result = [];

  if (array.length === 0) {
    return result;
  }

  const startDate = new Date(array[0][dateTimeKey]);
  const endDate = new Date(array[array.length - 1][dateTimeKey]);

  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const formattedDate = moment(currentDate).format("YYYY-MM-DD");

    const matchingObject = array.find(
      (obj) => moment(obj[dateTimeKey]).format("YYYY-MM-DD") == formattedDate
    );

    if (matchingObject) {
      result.push(matchingObject);
    } else {
      const newObjHere = createObjectWithZeroValues(array, 0);

      const newObj = {
        ...newObjHere,
        [dateTimeKey]: formattedDate,
      };

      result.push(newObj);
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
}

export function calculatePercentageOfANumberInArrayOfNumbers(
  numbers,
  targetNumber
) {
  const totalSum = numbers.reduce((sum, number) => sum + number, 0);
  const targetPercentage = (targetNumber / totalSum) * 100;

  const percentages = numbers.map((number) => {
    const percentage = (number / totalSum) * 100;
    return Number(percentage.toFixed(2));
  });

  return {
    percentages,
    targetPercentage: Number(targetPercentage.toFixed(2)),
  };
}

// function to get random hex color code
export function getRandomColor() {
  // Generate a random hexadecimal color code
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// GET BEACON NAME BY TYPE
export const getBeaconNameByType = (type) => {
  if (type == AllEnums.BeaconType.None) {
    return "None";
  } else if (type == AllEnums.BeaconType.Both) {
    return "Both";
  } else if (type == AllEnums.BeaconType.Entrance) {
    return "Entrance";
  } else if (type == AllEnums.BeaconType.Exit) {
    return "Exit";
  } else if (type == AllEnums.BeaconType.PaCoB) {
    return "PaCoB";
  }
};

// TIME DIFFERENCE CALCULATION FROM NOW

export const timeDifferenceFromNow = (dateTime, unit) => {
  const now = moment();
  const then = moment(dateTime);
  const diff = now.diff(then, unit);
  return diff;
};

// CAPITALIZE FIRST CHARACTER OF SENTENCE
export function capitalizeFirstCharOfSentence(sentence) {
  if (!sentence) return "";

  let sentences = sentence.split(". ");
  const whitelist = ["FOB", "FOBs", "FOB(s)", "VIP"];

  for (let i = 0; i < sentences.length; i++) {
    const unchangedWords = sentences[i].split(" ");
    let words = sentences[i].split(" ");

    for (let j = 0; j < words.length; j++) {
      let lowerCaseWord = words[j].toLowerCase();

      // Check if the last word ends with a period
      if (lowerCaseWord.endsWith(".")) {
        lowerCaseWord = lowerCaseWord.slice(0, -1);
      }

      const matchingWhitelist = whitelist.find(
        (item) => item.toLowerCase() === lowerCaseWord
      );
      if (matchingWhitelist) {
        words[j] = matchingWhitelist + (lowerCaseWord.endsWith(".") ? "." : "");
      } else {
        words[j] = lowerCaseWord;
      }
    }

    const lastWordIndex = unchangedWords.length - 1;
    const lastWord = unchangedWords[lastWordIndex];

    sentences[i] = words.join(" ");
    sentences[i] = sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1);

    // Add the period back if the original sentence had it

    if (lastWord.endsWith(".")) {
      sentences[i] += ".";
    }
  }

  return sentences.join(". ");
}

// TIME AGO IN UTC
export function timeAgoUtc(utcDateTime) {
  const now = moment.utc();
  const dt = moment.utc(utcDateTime);

  if (dt.isAfter(now)) {
    return "Just now";
  }

  const duration = moment.duration(now.diff(dt));

  if (duration.years() > 0) {
    return `${duration.years()} ${
      duration.years() === 1 ? "year" : "years"
    } ago`;
  }

  if (duration.months() > 0) {
    return `${duration.months()} ${
      duration.months() === 1 ? "month" : "months"
    } ago`;
  }

  if (duration.days() > 0) {
    return `${duration.days()} ${duration.days() === 1 ? "day" : "days"} ago`;
  }

  if (duration.hours() > 0) {
    return `${duration.hours()} ${
      duration.hours() === 1 ? "hour" : "hours"
    } ago`;
  }

  if (duration.minutes() > 0) {
    return `${duration.minutes()} ${
      duration.minutes() === 1 ? "minute" : "minutes"
    } ago`;
  }

  if (duration.seconds() > 5) {
    return `${duration.seconds()} seconds ago`;
  }

  if (duration.seconds() <= 5) {
    return "Just now";
  }

  return "";
}

// Function to check if some role has permission to some action or not

export const hasPermission = (moduleName, actionID) => {
  const roleModules = store.getState().roleModules.role_modules;
  const roleActions = store.getState().roleActions.role_actions;
  const role = store.getState().roles.role;

  const matchingRoleModule = roleModules?.find(
    (module) => module?.name == moduleName
  );

  const matchingRoleAction = roleActions?.find(
    (action) => action?.id == actionID
  );

  const roleModuleAction = role?.moduleActions?.find(
    (moduleAction) =>
      moduleAction?.action == matchingRoleAction?.id &&
      moduleAction?.module == matchingRoleModule?.id
  );

  if (roleModuleAction && roleModuleAction?.isAllowed) {
    return true;
  } else {
    return false;
  }
};
